// @ts-nocheck
import Head from 'next/head';

import cmsData from '@/constants/cms.json';
import { BNB, ETH, FANTOM, HEDERA, POLYGON, SD } from '@/constants/constants';
import { getBaseUrl, getCurrentChain, getQueryString } from '@/utils/common';

import { BNBFAQSchema, ETHFAQSchema, POLYGONFAQSchema } from './JSONScript';
import { usePathname } from 'next/navigation';

const noIndexFollow = 'noindex, follow';
const indexFollow = 'index, follow';

interface PageHeadProps {
  page:
    | 'stake'
    | 'defi'
    | 'index'
    | 'node-operators'
    | 'utility_pool'
    | 'bridge'
    | 'other';
  children: any;
  TOKEN?: string;
}
const faqSchema = (TOKEN: string) => {
  switch (TOKEN) {
    case ETH:
      return ETHFAQSchema;
    case POLYGON:
      return POLYGONFAQSchema;
    case BNB:
      return BNBFAQSchema;
    default:
      return ETHFAQSchema;
  }
};

const defiMetaRobots = (TOKEN: string) => {
  switch (TOKEN) {
    case SD:
      return indexFollow;
    default:
      return noIndexFollow;
  }
};

const stakeMetaRobots = (TOKEN: string) => {
  switch (TOKEN) {
    case HEDERA:
      return indexFollow;
    case FANTOM:
      return indexFollow;
    default:
      return noIndexFollow;
  }
};

export const getURLParams = () => {
  const queryString =
    typeof window === 'undefined'
      ? ''
      : getQueryString((window as any)?.location?.search);
  const origin = typeof window === 'undefined' ? '' : window?.location?.origin;
  return { queryString, origin };
};

const getPageData = (page: string) => {
  if (cmsData?.seo_header && cmsData.seo_header.length > 0) {
    let data: any = null;
    cmsData.seo_header.forEach((item: any) => {
      if (item.page === page) {
        data = item;
      }
    });
    return data;
  }
  return null;
};

const PageHeadCommon = ({ children, page, TOKEN }: PageHeadProps) => {
  if (page === 'utility_pool' && TOKEN !== 'SD') {
    const { queryString, origin } = getURLParams();
    const metaContent = `0; url='${origin}${getBaseUrl()}stake/'}`;
    return (
      <Head>
        <meta
          httpEquiv="Refresh"
          content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
        />
      </Head>
    );
  }
  if (page === 'bridge' && TOKEN !== 'ETH') {
    const { queryString, origin } = getURLParams();
    const metaContent = `0; url='${origin}${getBaseUrl()}stake/'}`;
    return (
      <Head>
        <meta
          httpEquiv="Refresh"
          content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
        />
      </Head>
    );
  }
  const data = getPageData(page);
  if (data) {
    return (
      <>
        <Head>
          {page === 'index' && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema()) }}
            />
          )}
          {data.title && <title>{data.title}</title>}
          {data.description && (
            <meta name="description" content={data.description} />
          )}
          {data.keywords && <meta name="keywords" content={data.keywords} />}
          {data.title && <meta property="og:title" content={data.title} />}
          {data.description && (
            <meta property="og:description" content={data.description} />
          )}
          {data.robots && <meta name="robots" content={data.robots} />}
          {data.canonical_url && (
            <link rel="canonical" href={data.canonical_url} />
          )}
        </Head>
        {data.h1 && <h1 style={{ height: 0, opacity: 0 }}>{data.h1}</h1>}
        {children}
      </>
    );
  }
  if (page === 'index') {
    return (
      <>
        <Head>
          {TOKEN === 'ETH' ? (
            <>
              <title>
                Ethereum Staking - Stake Ether & Earn Eth2 Rewards | Stader Labs
              </title>
              <meta
                name="description"
                content="Ethereum Staking - Stake Ether with Stader & earn rewards. Earn Eth2 rewards across the Ethereum ecosystem"
              />
            </>
          ) : (
            <title>{`${TOKEN} Liquid Staking`}</title>
          )}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema()) }}
          />
        </Head>
        <h1 style={{ height: 0, opacity: 0 }}>
          Ethereum Staking | ETH 2 Rewards
        </h1>
        {children}
      </>
    );
  }

  if (page === 'defi') {
    return (
      <>
        <Head>
          <title>DeFi | Stader</title>
          <meta name="robots" content={defiMetaRobots()} />
        </Head>
        {children}
      </>
    );
  }

  return (
    <>
      <Head>
        <meta name="robots" content={stakeMetaRobots()} />
      </Head>
      {children}
    </>
  );
};

const PageHeadSD = () => {
  const { queryString, origin } = getURLParams();
  const metaContent = `0; url='${origin}${getBaseUrl()}utility_pool/'}`;
  return (
    <Head>
      <meta
        httpEquiv="Refresh"
        content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
      />
    </Head>
  );
};

const PageHeadHedera = () => {
  const { queryString, origin } = getURLParams();
  const metaContent = `0; url='${origin}${getBaseUrl()}stake/'}`;
  return (
    <Head>
      <meta
        httpEquiv="Refresh"
        content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
      />
    </Head>
  );
};

const PageHeadFantom = () => {
  const { queryString, origin } = getURLParams();
  const metaContent = `0; url='${origin}${getBaseUrl()}stake/'}`;
  return (
    <Head>
      <meta
        httpEquiv="Refresh"
        content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
      />
    </Head>
  );
};

const PageHead = ({ page = 'other', children }: PageHeadProps) => {
  const pathname = usePathname()
  const TOKEN = getCurrentChain(pathname);

  switch (TOKEN) {
    case SD:
      return page === 'index' || page === 'stake' ? (
        <PageHeadSD />
      ) : (
        <PageHeadCommon page={page}>{children}</PageHeadCommon>
      );
    case HEDERA:
      return page === 'index' ? (
        <PageHeadHedera />
      ) : (
        <PageHeadCommon page={page}>{children}</PageHeadCommon>
      );

    case FANTOM:
      return page === 'stake' ? (
        <PageHeadCommon page={page}>{children}</PageHeadCommon>
      ) : (
        <PageHeadFantom />
      );

    default:
      return <PageHeadCommon page={page} TOKEN={TOKEN}>{children}</PageHeadCommon>;
  }
};

export default PageHead;
